import React, { useEffect } from "react";
import sal from "sal.js";

import "venobox/dist/venobox.min.css";

import TimnelineData from "../../data/home.json";
import Link from "next/link";

const RevenueSharing = () => {
  useEffect(() => {
    sal();

    import("venobox/dist/venobox.min.js").then((venobox) => {
      new venobox.default({
        selector: ".popup-video",
        maxWidth: window.innerWidth >= 992 ? "50%" : "100%",
      });
    });
  }, []);
  return (
    <>
      <div id="revenuesharing" className="rainbow-timeline-area rainbow-section-gap">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="section-title text-center"
                data-sal="slide-up"
                data-sal-duration="700"
                data-sal-delay="100"
              >
                <h4 className="subtitle ">
                  <span className="theme-gradient">Revenue Sharing & $GALAI Sale Partner</span>
                </h4>
                <h2 className="title w-600 mb--20">
                  Earn Revenue & Other Rewards<br /> By staking $GALAI Or Becoming A Sales Partner
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-5 offset-lg-1 mt--30">
              <div className="timeline-style-two bg-flashlight bg-color-blackest">
                <div className="row row--0">
                  <h4>Earn Revenue</h4>
                  <p>By staking your $GALAI tokens you will receive revenue and rewards from all revenue generated through our products and future deals. After the launch of $GALAI token we will introduce a staking program.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-5 mt--30">
              <div className="timeline-style-two bg-flashlight bg-color-blackest">
                <div className="row row--0">
                  <h4>GALAI Sales Partner</h4>
                  <p>Do you have what it takes to become a GALAI spokeperson and sell our products to your network? By doing so you will receive 10% MRR based on your network sales.</p>
                  <p>Are you able to get $GALAI in a better spot by delivering KOL's or deals like listings / partnership integrations? Amazing, we have advanced bonus rewards for that. Please contact us for further details.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-12 mt--30">
              <div className="button-group mt--50 text-center">
                <Link className="btn-default btn-large" href="https://docs.galai.io/revenue-sharing" target="_blank">
                  more read
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RevenueSharing;
