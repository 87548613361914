import React from 'react'

export default async function Form() {
    
    return (
        <div>
            <form className="newsletter-form" 
            // action={addOptinRecipient} 
            method='POST'>
                <div className="form-group">
                    <input
                        type="email"
                        placeholder="Enter Your Email Here"
                    />
                    <button
                        className="btn-default bg-solid-primary"
                        type="submit"
                    >
                        <i className="feather-arrow-right"></i>
                    </button>
                </div>
            </form>
        </div>
    )
}
