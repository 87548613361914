import React, { useEffect } from "react";
import sal from "sal.js";

import "venobox/dist/venobox.min.css";

import TimnelineData from "../../data/home.json";
import Link from "next/link";

const Tokenomics = () => {
  useEffect(() => {
    sal();

    import("venobox/dist/venobox.min.js").then((venobox) => {
      new venobox.default({
        selector: ".popup-video",
        maxWidth: window.innerWidth >= 992 ? "50%" : "100%",
      });
    });
  }, []);
  return (
    <>
      <div id="tokenomics" className="rainbow-timeline-area rainbow-section-gap">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="section-title text-center"
                data-sal="slide-up"
                data-sal-duration="700"
                data-sal-delay="100"
              >
                <h4 className="subtitle ">
                  <span className="theme-gradient">TOKENOMICS</span>
                </h4>
                <h2 className="title w-600 mb--20">
                  How $GALAI Token Is Setup
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-10 offset-lg-1 mt--30">
              <div className="timeline-style-two bg-flashlight bg-color-blackest" style={{display: "flex"}}>
                <div style={{width: "49%"}}>
                  Tokenomics
                  <div>
                    <ul>
                      <li>Token Name: GALAI</li>
                      <li>Blockchain Platform: Ethereum</li>
                      <li>Total Supply: 1,000,000,000 (1 Billion)</li>
                      <li>Token Split
                        <ul>
                          <li>30% LP: 300,000,000 (300 Million)</li>
                          <li>15% Presale: 150,000,000 (150 Million)</li>
                          <li>15% Listing Treasury: 150,000,000 (150 Million)</li>
                          <li>15% Marketing treasury: 150,000,000 (150 Million)</li>
                          <li>15% Ecosystem treasury: 150,000,000 (150 Million)</li>
                          <li>10% Team Treasury: 100,000,000 (100 Million)</li>
                        </ul>
                      </li>
                    </ul>

                  </div>
                </div>
                <div style={{width: "49%"}}>
                  GRAPH
                </div>
              </div>
              <div className="button-group mt--50 text-center">
                <Link className="btn-default btn-large" href="https://docs.galai.io" target="_blank">
                  pitchdeck
                </Link>
              </div>
            </div>
          </div>
        </div >
      </div >
    </>
  );
};

export default Tokenomics;
