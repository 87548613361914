import { useEffect } from "react";
import Link from "next/link";

import "venobox/dist/venobox.min.css";

const Home = () => {
  useEffect(() => {
    import("venobox/dist/venobox.min.js").then((venobox) => {
      new venobox.default({
        selector: ".popup-video",
        maxWidth: window.innerWidth >= 992 ? "50%" : "100%",
      });
    });
  }, []);
  return (
    <>
      <div
        className="slider-area slider-style-1 variation-default slider-bg-image bg-banner1"
        data-black-overlay="1"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="inner text-center mt--60">
                <h1 className="title display-one">
                  Unlock The Power Of <br />
                  <span className="theme-gradient">GALAI</span>
                  {/* With{" "}
                  <br />
                  <span className="color-off">GALAIMML</span> */}
                </h1>
                <p className="b1 desc-text">
                  A MML with all crypto AI features developed by GALAI.io
                </p>
                <div className="button-group">
                  <Link
                    className="btn-default bg-light-gradient btn-large"
                    href="https://docs.galai.io"
                  >
                    <div className="has-bg-light"></div>
                    <span>pitchdeck</span>
                  </Link>
                </div>
                <div style={{fontSize: "20px", fontWeight: "bold", marginTop: "12px"}} className="theme-gradient">Join Our Community</div>
                <div className="button-group" style={{marginTop: "12px"}}>

                  <Link href="#">
                    <svg width="30px" fill="#fff" height="30px" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve" xmlnsSerif="http://www.serif.com/"><path style={{transform: "scale(120%)"}} width="30" height="30" id="telegram-3" d="M19,24l-14,0c-2.761,0 -5,-2.239 -5,-5l0,-14c0,-2.761 2.239,-5 5,-5l14,0c2.762,0 5,2.239 5,5l0,14c0,2.761 -2.238,5 -5,5Zm-2.744,-5.148c0.215,0.153 0.491,0.191 0.738,0.097c0.246,-0.093 0.428,-0.304 0.483,-0.56c0.579,-2.722 1.985,-9.614 2.512,-12.09c0.039,-0.187 -0.027,-0.381 -0.173,-0.506c-0.147,-0.124 -0.351,-0.16 -0.532,-0.093c-2.795,1.034 -11.404,4.264 -14.923,5.567c-0.223,0.082 -0.368,0.297 -0.361,0.533c0.008,0.235 0.167,0.44 0.395,0.509c1.578,0.471 3.65,1.128 3.65,1.128c0,0 0.967,2.924 1.472,4.41c0.063,0.187 0.21,0.334 0.402,0.384c0.193,0.05 0.397,-0.002 0.541,-0.138c0.811,-0.765 2.064,-1.948 2.064,-1.948c0,0 2.381,1.746 3.732,2.707Zm-7.34,-5.784l1.119,3.692l0.249,-2.338c0,0 4.324,-3.9 6.79,-6.124c0.072,-0.065 0.082,-0.174 0.022,-0.251c-0.06,-0.077 -0.169,-0.095 -0.251,-0.043c-2.857,1.825 -7.929,5.064 -7.929,5.064Z" /></svg></Link>
                  <Link href="https://twitter.com/GALAI_io" target="_blank"><svg fill="#fff" width="30" height="30" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1000.8 936.7" xmlSpace="preserve"><g id="layer1" fill="#fff" transform="translate(52.390088,-25.058597)"><g id="path1009"><path class="st0" d="M-50,25.1l386.4,516.6L-52.4,961.7h87.5L375.5,594l275,367.8h297.8L540.3,416l361.9-391h-87.5L501.1,363.8 L247.8,25.1H-50z M78.7,89.5h136.8l604.1,807.8H682.9L78.7,89.5z"></path></g></g></svg></Link>

                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
